import Vue from 'vue'
import VueRouter from 'vue-router'
import loadIng from '../views/loading.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: loadIng
  },
  {
    path: '/loading',
    name: 'loading',
    component: () => import('../views/loading.vue')
  },
  {
    path: '/HomeView',
    name: 'HomeView',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/HomeViewPro',
    name: 'HomeViewPro',
    component: () => import('../views/HomeViewPro.vue')
  },
  {
    path: '/commonTwo',
    name: 'commonTwo',
    component: () => import('../views/commonTwo.vue')
  },
  {
    path: '/commonTwoPro',
    name: 'commonTwoPro',
    component: () => import('../views/commonTwoPro.vue')
  },
  {
    path: '/baseView',
    name: 'baseView',
    component: () => import('../views/baseView.vue')
  },
  {
    path: '/baseViewPro',
    name: 'baseViewPro',
    component: () => import('../views/baseViewPro.vue')
  },
  // 厦门小歪专用过审模版
  {
    path: '/baseViewXmxy',
    name: 'baseViewXmxy',
    component: () => import('../views/baseViewXmxy.vue')
  },
  // 启迪专用
  {
    path: '/commonPage',
    name: 'commonPage',
    component: () => import('../views/commonPage.vue')
  },
  {
    path: '/commonPagePro',
    name: 'commonPagePro',
    component: () => import('../views/commonPagePro.vue')
  },
  // 启迪的是二确的模版
  {
    path: '/QiDiTemplate',
    name: 'QiDiTemplate',
    component: () => import('../views/QiDiTemplate.vue')
  },
  // 二确通用模版并且带滚动条
  {
    path: '/towConfrimgundong',
    name: 'towConfrimgundong',
    component: () => import('../views/towConfrimgundong.vue')
  },
  {
    path: '/commonTwoProC',
    name: 'commonTwoProC',
    component: () => import('../views/commonTwoProC.vue')
  },
  // 北海星辰模版
  {
    path: '/bhxc_towConfrimgundong',
    name: 'bhxc_towConfrimgundong',
    component: () => import('../views/bhxc_towConfrimgundong.vue')
  },
  // 基础通用模版 后台管理系统上传图片前端接受图片 进行替换产品
  {
    path: '/universalTemplate',
    name: 'universalTemplate',
    component: () => import('../views/universalTemplate.vue')
  },
  // 基础通用模版 (第二套 换了登录按钮和活动说明的样式)
  {
    path: '/universalTwoTemplate',
    name: 'universalTwoTemplate',
    component: () => import('../views/universalTwoTemplate.vue')
  }, 
  // 中转页模版
  {
    path: '/transferPage',
    name: 'transferPage',
    component: () => import('../views/transferPage.vue')
  }, 
  // 活动通用模版 
  {
    path: '/activeCommonTemplate',
    name: 'activeCommonTemplate',
    component: () => import('../views/activeCommonTemplate.vue')
  }, 
  // 活动通用2模版 (提交按钮文字修改 运营说 文字要还 不然转化不好 后续做个功能之后这个就可以废弃了)
  {
    path: '/activeBTNTemplate',
    name: 'activeBTNTemplate',
    component: () => import('../views/activeBTNTemplate.vue')
  }, 
  // 活动通用3模版 (添加各类协议过审 后续做个功能之后这个就可以废弃了)
  {
    path: '/activeThrTemplate',
    name: 'activeThrTemplate',
    component: () => import('../views/activeThrTemplate.vue')
  }, 
  // 支付宝支付通用模版 
  {
    path: '/alipayTemplate',
    name: 'alipayTemplate',
    component: () => import('../views/alipayTemplate.vue')
  }, 
  // 支付宝华为模版
  {
    path: '/alipayHuaweiTemplate',
    name: 'alipayHuaweiTemplate',
    component: () => import('../views/alipayHuaweiTemplate.vue')
  },
  // 流量果通用模版 
  {
    path: '/llgTemplate',
    name: 'llgTemplate',
    component: () => import('../views/llgTemplate.vue')
  }, 
  // 互客模版一
  {
    path: '/HuKeTemplate',
    name: 'HuKeTemplate',
    component: () => import('../views/HuKeTemplate.vue')
  }, 
  // 互客模版二
  {
    path: '/HuKeTwoTemplate',
    name: 'HuKeTwoTemplate',
    component: () => import('../views/HuKeTwoTemplate.vue')
  }, 
  // 视频彩铃模版
  {
    path: '/vedioTemplate',
    name: 'vedioTemplate',
    component: () => import('../views/vedioTemplate.vue')
  }, 
  // 启迪小福券12月30元
  {
    path: '/xfqtemplate1230',
    name: 'xfqtemplate1230',
    component: () => import('../views/xfqtemplate1230.vue')
  }, 
  // 启迪小福券12月30元
  {
    path: '/xfqtemplate0630',
    name: 'xfqtemplate0630',
    component: () => import('../views/xfqtemplate0630.vue')
  }, 
  // 启迪小福券06月20元
  {
    path: '/xfqtemplate0620',
    name: 'xfqtemplate0620',
    component: () => import('../views/xfqtemplate0620.vue')
  }, 
  // 启迪小福券12月20元
  {
    path: '/xfqtemplate1220',
    name: 'xfqtemplate1220',
    component: () => import('../views/xfqtemplate1220.vue')
  }, 
  // 全（是否显示带手机号的跑马灯效果、录入手机号后是否自动获取验证码、、录入手机号后是否自动勾选用户协议、提交验证码后是否显示二确弹窗以及自定义弹窗文字内容）自定义通用模版
  {
    path: '/customTemplate',
    name: 'customTemplate',
    component: () => import('../views/customTemplate.vue')
  }, 
  // 支付宝直接领取通用模版
  {
    path: '/alipaySubmitTemplate',
    name: 'alipaySubmitTemplate',
    component: () => import('../views/alipaySubmitTemplate.vue')
  }, 
  // 通用百分比模版
  {
    path: '/activePercentTemplate',
    name: 'activePercentTemplate',
    component: () => import('../views/activePercentTemplate.vue')
  }, 
  // 支付宝支付通用模版
  {
    path: '/alipayNoprotocolTemplate',
    name: 'alipayNoprotocolTemplate',
    component: () => import('../views/alipayNoprotocolTemplate.vue')
  }, 
  // 支付宝喜马拉雅模版
  {
    path: '/alipayTemplateXmly',
    name: 'alipayTemplateXmly',
    component: () => import('../views/alipayTemplateXmly.vue')
  }, 
  // 支付宝喜马拉雅模版二 无验证码
  {
    path: '/alipayTXmly',
    name: 'alipayTXmly',
    component: () => import('../views/alipayTXmly.vue')
  }, 
  // 支付宝喜马拉雅模版三 流量果广告位
  {
    path: '/alipayTXmlyLlg',
    name: 'alipayTXmlyLlg',
    component: () => import('../views/alipayTXmlyLlg.vue')
  },
  // TCL模版
  {
    path: '/TclTemplate',
    name: 'TclTemplate',
    component: () => import('../views/TclTemplate.vue')
  },
  // 网易有道词典<协议>模版
  {
    path: '/wangyiTemplate',
    name: 'wangyiTemplate',
    component: () => import('../views/wangyiTemplate.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

export default router
